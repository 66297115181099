import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { toast } from "react-hot-toast";

// Styled components
const OuterDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px;
  gap: 30px;
  background-color: rgb(248, 253, 255);
`;

const FormWrapper = styled.div`
  margin: 20px;
  padding: 20px;
  width: 600px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  gap: 20px;
`;

const FormHeader = styled.h2`
  text-align: center;
  font-size: 1.5rem;
  color: #333;
`;

const InputBox = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  outline: none;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  outline: none;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const BentoForm = () => {
  const [personality, setPersonality] = useState({
    traitsTitle: "",
    traitsDescription: "",
    traitsLink: "",
    skillPreferencesTitle: "",
    skillPreferencesDescription: "",
    skillPreferencesLink: "",
    careerPreferncesTitle: "",
    careerPreferncesDescription: "",
    careerPreferncesLink: "",
  });

  const [temperament, setTemperament] = useState({
    traitsTitle: "",
    traitsDescription: "",
    traitsLink: "",
    skillPreferencesTitle: "",
    skillPreferencesDescription: "",
    skillPreferencesLink: "",
    careerPreferncesTitle: "",
    careerPreferncesDescription: "",
    careerPreferncesLink: "",
  });

  const handleChange = (e, type) => {
    const { name, value } = e.target;
    if (type === "personality") {
      setPersonality({ ...personality, [name]: value });
    } else if (type === "temperament") {
      setTemperament({ ...temperament, [name]: value });
    }
  };

  const handleSubmit = async (data, type) => {
    try {
      const response = await axios.post(
        `https://lumitestserver-raisec.vercel.app/save-${type}-info`,
        data
      );
      toast.success(`${type} information saved successfully!`);
      console.log(response.data);

      // Reset the form
      if (type === "personality") {
        setPersonality({
          traitsTitle: "",
          traitsDescription: "",
          traitsLink: "",
          skillPreferencesTitle: "",
          skillPreferencesDescription: "",
          skillPreferencesLink: "",
          careerPreferncesTitle: "",
          careerPreferncesDescription: "",
          careerPreferncesLink: "",
        });
      } else if (type === "temperament") {
        setTemperament({
          traitsTitle: "",
          traitsDescription: "",
          traitsLink: "",
          skillPreferencesTitle: "",
          skillPreferencesDescription: "",
          skillPreferencesLink: "",
          careerPreferncesTitle: "",
          careerPreferncesDescription: "",
          careerPreferncesLink: "",
        });
      }
    } catch (error) {
      toast.error(`Error saving ${type} information.`);
      console.error(error.response?.data || error);
    }
  };

  const renderForm = (data, type) => (
    <FormWrapper>
      <FormHeader>{type === "personality" ? "Personality" : "Temperament"} Form</FormHeader>
      {Object.keys(data).map((key) => (
        <InputBox key={key}>
          {key.includes("Description") ? (
            <TextArea
              name={key}
              placeholder={key}
              value={data[key]}
              onChange={(e) => handleChange(e, type)}
            />
          ) : (
            <Input
              type={key.includes("Link") ? "url" : "text"}
              name={key}
              placeholder={key}
              value={data[key]}
              onChange={(e) => handleChange(e, type)}
            />
          )}
        </InputBox>
      ))}
      <Button onClick={() => handleSubmit(data, type)}>Submit</Button>
    </FormWrapper>
  );

  return (
    <OuterDiv>
      {renderForm(personality, "personality")}
      {renderForm(temperament, "temperament")}
    </OuterDiv>
  );
};

export default BentoForm;
