import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import profile from '../img/profile-img-grey.jpg';
import { toast } from 'react-hot-toast';
import tempimg from '../img/tempimg.jpg';
import styles from '../styles/AllTests.module.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import handleTestClick from './Tests';

function TemperamentsResults() {
  const [temperament, setTemperament] = useState([]);
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [_id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [expandedTemperaments, setExpandedTemperaments] = useState([]);
  const [showTemperament, setShowTemperament] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState([]); // New state for additional info

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login page if user is not logged in
      navigate('/');
    } else {
      // Fetch user ID from session storage
      const newUuid = localStorage.getItem('uuid');
      const username = localStorage.getItem('username');
      const _id = localStorage.getItem('_id');
      const email = localStorage.getItem('email');
      setUserId(newUuid);
      setUsername(username);
      setId(_id);
      setEmail(email);
    }
  }, [navigate]);

  useEffect(() => {
    const fetchStoredTemperaments = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-Temperaments/${userId}`);
        setTemperament(response.data);
        if (response.data.length > 0) {
          setShowTemperament(true);
        }
      } catch (error) {
        console.error('Error fetching stored careers:', error);
      }
    };

    if (userId) {
      fetchStoredTemperaments();
    }
  }, [userId]);


  // New useEffect for fetching the temperament info from the new endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/get-additional-info`);
          setAdditionalInfo(response.data.map((info) => info.temperament));
          console.log('Additional info:', response.data.map((info) => info.temperament));
      } catch (error) {
        toast.error(`Error fetching information.`);
        console.error(error.response?.data || error);
      }
    };
  
    fetchData();
  }, []);


  const handleToggleDescription = (index) => {
    const newExpandedTemperaments = [...expandedTemperaments];
    newExpandedTemperaments[index] = !newExpandedTemperaments[index];
    setExpandedTemperaments(newExpandedTemperaments);
  };

  const handleToggleTemperament = () => {
    setShowTemperament(!showTemperament);
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById('results-container');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`results-${username}.pdf`);
    });
  };

  return (
    showTemperament ? (
      <div className="Student-result">
        <div className={styles.Resultback}>
          <button onClick={() => window.history.back()} className={styles.ResultbackBtn}>Back</button>
        </div>
        <div onClick={handleToggleTemperament} className={styles.ResultHeading}>
          <div className={styles.headingdiv}>
            <h2>Congratulations {username}! Your results are</h2>
            <p>
              Answer all the following questions based on your preference. 
              Try to avoid choosing the middle answers for more accurate results.
            </p>
          </div>
        </div>

        <div className={styles.studentTemperamentList}>
          <div className={styles.studentTemperamentcards}>
            <div className={styles.uppercard}>
              <div className={styles.uppercardleft}>
                {temperament.map((temp, index) => (
                  <div className={styles.tempdescription} key={index}>
                    <h2 onClick={() => handleToggleDescription(index)}>{temp.temperamentName}</h2>
                    <div className={styles.descriptioninnerbox}>
                      <p style={{fontSize: '0.7rem'}}>{temp.description}</p>
                      <button className={styles.startestbtn} onClick={() => navigate('/temperament-test')}>
                        Start Test
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.uppercardright}>
                <img className={styles.tempprofileimg} src={tempimg} alt="profile" />
              </div>
            </div>

          {additionalInfo.map((info, index) => (
            <div className={styles.lowercard}>
                <div className={styles.card} key={index}>
                  {/* <h3>{info.traitsTitle}</h3> */}
                  <h3>Most Prominent Traits</h3>
                  <p style={{fontSize: '0.7rem'}}>{info.traitsDescription}</p>
                  <button className={styles.startestbtn}>
                    Start Test
                  </button>
                </div>
    
                <div className={styles.card}>
                  {/* <h3>{info.skillPreferencesTitle}</h3> */}
                  <h3>Skill Preferences</h3>                
                  <p style={{fontSize: '0.7rem'}}>{info.skillPreferencesDescription}</p>
                  <button className={styles.startestbtn}>
                    Start Test
                  </button>
                </div>
    
                <div className={styles.card}>
                  {/* <h3>{info.careerPreferncesTitle}</h3> */}
                  <h3>Career Preferences</h3>
                  <p style={{fontSize: '0.7rem'}}>{info.careerPreferncesDescription}</p>
                  <button className={styles.startestbtn}>
                    Start Test
                  </button>
                </div>
              </div>
          ))}
          </div>
          <button onClick={handleDownloadPDF} className="temp-btn">Download</button>
        </div>
      </div>
    ) : (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100vh', alignItems: 'center' }}>
        No results found
      </div>
    )
  );
}

export default TemperamentsResults;
